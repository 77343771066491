import { graphql } from "gatsby"
import React, { useState } from "react"
import BlogArticles from "../components/blog/blog-articles"
import BlogCategories from "../components/blog/blog-categories"
import DownloadPopup from "../components/download-popup"
import Footer from "../components/footer"
import Menu from "../components/menu/menu"
import MenuMobile from "../components/menu/menu-mobile"
import SEO from "../components/seo"
import { ContentfulBlogArticleType } from "../types/blog-types"
import { pathToString } from "../utilities/string-path-convertor"
import BreadCrumbs from "../components/BreadCrumbs"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import CarnivalCruisePopUp from "../components/CarnivalCruisePopUp"

export const query = graphql`
  query BlogMainPageQuery($categoryId: String, $skip: Int!, $limit: Int!) {
    allContentfulBlogArticle(
      sort: { order: DESC, fields: compose__page___seo___createdAt }
      filter: {
        category: { name: { ne: null }, id: { eq: $categoryId } }
        compose__page: { elemMatch: { title: { ne: null } } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          createdAt(formatString: "D MMMM, YYYY")
          author {
            name
          }
          category {
            name
            id
          }
          compose__page {
            title
            slug
            seo {
              createdAt
            }
            impressionLink {
              impressionLink
            }
          }
          id
          color
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 285, quality: 80)
              }
            }
            title
          }
          text {
            raw
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        itemCount
        pageCount
        totalCount
        perPage
        hasPreviousPage
      }
    }

    contentfulBlogCategoy(id: { eq: $categoryId }) {
      id
      name
      description {
        raw
      }
      socialImage {
        url
      }
    }
  }
`;

const BlogPageTemplate = ({ data, pageContext }) => {
  const articles: ContentfulBlogArticleType["allContentfulBlogArticle"]["edges"] =
    data.allContentfulBlogArticle.edges
  const pageInfo: ContentfulBlogArticleType["allContentfulBlogArticle"]["pageInfo"] =
    data.allContentfulBlogArticle.pageInfo
  const [showDownloadPopup, setShowDownloadPopup] = useState<boolean>(false)
  const [shouldShowBanner, setShouldShowBanner] = useState(false);
  const { categorySlug, categoryId } = pageContext
  const category = data.contentfulBlogCategoy

  let title = "Jackpot Party Slots Blog: Read, Play, Win, Repeat."
  if (categorySlug) {
    title = `Jackpot Party Slots Blog: ${pathToString(categorySlug)}`
  }

  const seo = {
    title,
    description:
      "Join millions of players and spin Jackpot Party for FREE! #1 for authentic online Vegas casino slot games with 300+ FREE SLOTS to play! Start spinning now!",
    follow: pageInfo.currentPage > 1,
    no_index: pageInfo.currentPage > 1,
    socialImage: category.socialImage
  };

  return (
    <>
      <SEO
        data={seo}
        shouldShowBanner={shouldShowBanner}
        setShouldShowBanner={setShouldShowBanner}
      />
      {/* <CarnivalCruisePopUp onClick={() => setShowDownloadPopup(true)} /> */}

      <DownloadPopup
        onCloseClick={() => setShowDownloadPopup(false)}
        show={showDownloadPopup}
      />

      <div className={`app-container blog-container ${shouldShowBanner ? "show-banner" : ""}`}>
        <MenuMobile shouldShowBanner={shouldShowBanner} onDownloadClick={() => setShowDownloadPopup(true)} />
        <Menu
          hideOnScroll={false}
          onDownloadClick={() => setShowDownloadPopup(true)}
        />

        <main>
          <BlogCategories />
          <div className="blog-main-container">
            {!!categoryId && (
              <div className="flex mt-10 flex-col">
                <BreadCrumbs
                  crumbs={[
                    {
                      label: "Blog",
                      to: "/blog",
                      hasLink: true,
                    },
                  ]}
                />
                <h1 className="text-[24px] md:text-[40px] font-bold capitalize">{category.name.toLowerCase()}</h1>
                {!!category.description?.raw &&
                  <p className="mt-2 text-[14px] text-gray-500">
                    {documentToPlainTextString(
                      JSON.parse(category.description?.raw)
                    )}
                  </p>
                }
              </div>
            )}
            <BlogArticles
              data={articles}
              pageInfo={pageInfo}
              categorySlug={categorySlug}
              category={category}
            />
          </div>
        </main>

        <Footer />
      </div>
    </>
  )
}

export default BlogPageTemplate
